<template>
  <div class="page-up">
    <Gallery v-if='!show_info' :tag='tag' @click='show' :options='options' :column='5'></Gallery>
    <transition name="slide">
      <Info
        v-if='show_info'
        :info='info_data'
        :index='index'
        :total='total'
        @next='next()'
        @prev='prev()'
        @click='clear()'
      ></Info>
    </transition>
  </div>
</template>

<script>
import Page from './../mixins/Page.vue';
import Gallery from './../components/gallery/Gallery.vue';
import Info from './../components/modal/modal-art-details.vue';
import ArtJSON from './../json/art.json';

export default {
  mixins:[Page],
  props:[],
  data(){
    return{
      options: ArtJSON,
      tag:[],
      show_info: false,
      info_data: null,
      total: ArtJSON.length,
      index: 0
    }
  },
  methods:{
    controls:function(val){
      if(!this.show_info)
        this.keyRun(val, "right", this.setView, this.PAGE.HOME);
    },
    clear:function(){
      this.show_info = false;
      this.info_data = null
    },
    show:function(index){
      this.show_info = true;
      this.info_data = this.options.find((item) => item.id == index);
      this.index = (index-1);
    },
    next:function(){
      let index = this.options.findIndex((item) => item.id == this.info_data.id);
      if(index + 1 < this.options.length){
        this.info_data = this.options[index + 1];
        this.index = index + 1;
      }
      else{
        this.show_info = false;
      }
    },
    prev:function(){
      let index = this.options.findIndex((item) => item.id == this.info_data.id);
      if(index - 1 >= 0){
        this.info_data = this.options[index - 1];
        this.index = index - 1;
      }
      else{
        this.show_info = false;
      }
    }
  },
  components:{
    Gallery,
    Info
  }
}
</script>
