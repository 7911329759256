<template>
  <div class="code-page">
    <div class="code-prev-div" v-if='value == PAGE.CODE'>
      <h1>{{info_data.title}}</h1>
      <p>{{typing}}</p>
    </div>
    <div :class='"circle " + animation' >
      <transition name="rotate-over">
        <Radial ref="radial" v-if='value == PAGE.CODE' :show='show_info' @onClick='showProject' v-model='project_index'></Radial>
      </transition>
      <div class="circle-inner anim-rotate-right">
        <span class = "hidden"></span>
        <div class="circle-inner anim-rotate-left-2">
          <span class = "hidden"></span>
        </div>
      </div>
    </div>
    <transition name="slide">
      <Info v-if='show_info' v-model='project_index' @pageChange="modalScroll" :project='info_data' :pages='info_data.pages' @click='clear()'></Info>
    </transition>
  </div>
</template>

<script>
import Page from './../mixins/Page.vue';
import Info from './../components/modal/modal-project-details.vue';
import Radial from './../components/Radial.vue';
import CodeJSON from './../json/code.json';
export default {
  mixins:[Page],
  props:["value"],
  data(){
    return{
      options: CodeJSON,
      show_info: false,
      info_data: null,
      project_index: 1,
      desc: ''
    }
  },
  watch:{
    project_index:function(val){
      this.info_data = this.options[val];
      this.endType();
      this.startType(this.info_data.desc);
    },
    value:function(val){
      if(val == this.PAGE.CODE){
        this.info_data = this.options[1];
        this.endType();
        this.startType(this.info_data.desc);
      }
    },
  },
  computed:{
    animation:function(){
      switch (this.value) {
        case this.PAGE.ABOUT:
          return "about-slide-down";
        case this.PAGE.HOME:
          return "about-slide-up";
        case this.PAGE.CODE:
          return "";
      }
      return "anim-rotate-left";
    }
  },
  methods:{
    showProject:function(index){
      if(index == null){
        this.project_index = 1;
        this.endType();
        this.$emit("setView", this.PAGE.HOME);
      }
      else{
        this.show_info = true;
        this.info_data = this.options.find((item) => item.id == index);
      }
    },
    clear:function(){
      this.show_info = false;
    },
    modalScroll:function(index){
      this.project_index = index;
      this.$refs.radial.adjust(index);
    }
  },
  created:function(){
    this.info_data = this.options[0];
  },
  components:{
    Radial,
    Info
  }
}
</script>
