<template>
  <div class="loading">
    <div class="image">
      <input type="text" v-model="string">
      <button type="button" name="button" @click='enter()'>OK</button>
    </div>
  </div>
</template>

<script>
export default {
  props:["value"],
  data(){
    return{
      string: null,
      CODE:"testdemo01"
    }
  },
  methods:{
    enter:function(){
      if(this.string === this.CODE){
        this.$emit('input', false);
      }
    }
  }
}
</script>
