<template>
  <div class="modal">
    <div class="image-center">
      <div class="page-no-mobile">
        <span>{{index + 1}}/{{total}}</span>
      </div>
      <div class="wide">
        <img :src='image_src' alt="" loading="lazy">
      </div>
      <hr>
      <div class="info">
        <div class="page-no">
          <span>{{index + 1}}/{{total}}</span>
        </div>
        <h1>{{info.title}}</h1>
        <p>{{info.desc}}</p>
        <div class="buttons-div">
          <button class="btn-arrow-left" type="button" name="button" @click='$emit("prev")'> </button>
          <button class="btn-grid" type="button" name="button" @click='$emit("click")'></button>
          <button class="btn-arrow-right" type="button" name="button" @click='$emit("next")'></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Page from './../../mixins/Page.vue';
export default {
  mixins:[Page],
  props:["info", "value", "index", "total"],
  data(){
    return{}
  },
  watch:{
    touch:function(val){
      this.touchRun(val, "left", this.swipe, "prev");
      this.touchRun(val, "right", this.swipe, "next");
    },
    keyboard:function(val){
      this.keyRun(val, "left", this.swipe, "prev");
      this.keyRun(val, "right", this.swipe, "next");
    }
  },
  methods:{
    swipe:function(dir){
      this.$emit(dir)
    }
  },
  computed:{
    image_src:function(){
      var images = require.context('./../../assets/icons', false)
      return images("./" + this.info.img)
    }
  },
  mounted:function(){
  }
}
</script>
