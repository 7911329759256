<template>
  <div id="app" tabindex="-1">
    <router-view></router-view>
  </div>
</template>

<script>
export default{
  data(){
    return{}
  },
}
</script>
