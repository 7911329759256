<template>
    <div class="home">
      <Code v-model='value' @setView='setView'></Code>
        <div class="welcome-msg">
          <transition name="slide-art">
          <p class="right" v-if='value == PAGE.HOME'>
            <span class='intro'>Hi, I'm Aileen!</span>
            <span class="intro-tag">I'm a NYC based <b>Artist</b><br></span>
            <span class="intro-tag">and <b>Coder</b>.</span>
          </p>
          </transition>
        </div>
      <transition name="rotate-code">
        <div class="libra-div" v-if='value != PAGE.CODE'>
          <transition name="home-about">
          <div class="wrapper" v-if='value != PAGE.ABOUT'>
            <button class="libra-btn" @click='setView(PAGE.ABOUT)' tabindex='-1'>
              <!-- <Lottie :animationData="require('@/assets/anim/libra')" :loop="true"/> -->
              <Lottie path='anim/libra.json' :loop="true"/>
            </button>
            <div class="libra-buttons-div">
              <button type="button" class="btn-code" name="button" @click='setView(PAGE.CODE)' tabindex='-1'>
                <span class="b1">CODE</span>
              </button>
              <button type="button" class="btn-art" name="button" @click="setView(PAGE.ART)" tabindex='-1'>
                <span class="b1">ART</span>
              </button>
            </div>
          </div>
        </transition>
        </div>
      </transition>
    </div>
</template>

<script>
import Page from './../mixins/Page.vue';
import Code from './Code.vue';

export default {
  mixins:[Page],
  props:['value'],
  name: 'Home',
  data(){
    return{
      vue_canvas: null,
      padding: 0,
      animation:""
    }
  },
  computed:{
    clickable:function(){
      return (this.value != this.PAGE.HOME);
    }
  },
  methods:{
    setAnimation:function(val){
      switch (val) {
        case this.PAGE.ABOUT:
          this.animation = "home-about";
          break;
      }
    },
    setView:function(view){
      this.setAnimation(view);
      this.$emit("setView", view);
    },
  },
  mounted:function(){},
  components: {
    Code
  }
}
</script>
