<template>
  <div id="gallery">
    <GButton v-for='info in filtered_buttons' v-bind:key='info.id' :info='info' @click='show(info.id)'></GButton>
  </div>
</template>

<script>
import GButton from './GalleryButton.vue';
import {intersection} from 'lodash';

export default {
  props:["value", "options", "tag", "column"],
  data(){
    return{

    }
  },
  computed:{
    filtered_buttons:function(){
      if(this.tag.length > 0)
        return this.options.filter((item) => intersection(item.tag, this.tag).length > 0);

      return this.options;
    }
  },
  methods:{
    show:function(index){
      this.$emit('click', index);
    }
  },
  created:function(){

  },
  components:{
    GButton
  }
}
</script>

<style>

</style>
