<template>
  <div></div>
</template>

<script>
import PAGE from './../constants.js'
import Keyboard from './Keyboard.vue';
import Swipe from './Swipe.vue';

export default {
  mixins:[Keyboard, Swipe],
  props:["value"],
  data(){
    return{
      PAGE:PAGE,
      typing: '',
      isType: false
    }
  },
  methods:{
    setView:function(val){
      this.$emit("input", val);
    },
    startType(string){
      this.isType = true;
      this.typing = '';
      this.typeText(0, string)
    },
    typeText(index, string){
      if(index < string.length && this.isType){
        this.typing = string.substring(0, index);
        setTimeout(() => {
          this.typeText(index + 1, string);
        }, 10);
      }
      else{
        return;
      }
    },
    endType(){
      this.typing = '';
      this.isType = false;
    }
  }
}
</script>
