<template>
  <div class="radial-div">
    <div class="radial rotating">
      <div class="radial-slot" v-for='i in MAX_BUTTON' v-bind:key='i'>
        <span>
            <button type="button" name="button" @click="click(i)" :disabled='is_active(i)'>
              <img :src='image_src(i)' alt="">
            </button>
        </span>
      </div>
    </div>
    <div class="radial-center">
      <button class="star-rotate-left" type="button" name="button" @click='rotate(-1)'>LEFT</button>
      <button class="star-return" type="button" name="button" @click="click(null)">RETURN</button>
      <button class="star-rotate-right" type="button" name="button" @click='rotate(1)'>RIGHT</button>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Page from './../mixins/Page.vue';

export default {
  mixins:[Page],
  props:["value", "show"],
  data(){
    return{
      offset: 0,
      degrees: 0,
      MAX_BUTTON: 6,
      amt_rotate:60,
      hover:''
    }
  },
  watch:{
    touch:function(val){
      if(!this.show)
      {
        this.touchRun(val, "left", this.rotate, -1);
        this.touchRun(val, "right", this.rotate, 1);
        this.touchRun(val, "up", this.click, null);
        this.touchRun(val, "down", this.click, this.value + 1);
      }
    },
    keyboard:function(val){
      if(!this.show)
      {
        this.keyRun(val, "left", this.rotate, -1);
        this.keyRun(val, "right", this.rotate, 1);
        this.keyRun(val, "up", this.click, null);
        this.keyRun(val, "down", this.click, this.value + 1);
      }
    }
  },
  methods:{
    image_src:function(index){
      var name = "btn-code-" + index + ".svg";
      var images = require.context('./../assets/buttons/code', false)
      return images("./" + name)
    },
    is_active:function(index){
      return ((this.value + 1) == index) ? false : true;
    },
    id:function(index){
      return index;
    },
    click(index){
      this.$emit("onClick", index);
    },
    rotate(index){
      this.degrees -= (this.amt_rotate * index);

      let newIndex = (this.value + index) % this.MAX_BUTTON;
      newIndex = (newIndex < 0) ? (this.MAX_BUTTON-1) : newIndex;
      this.$emit("input", newIndex);

      $(".rotating").css({'transform' : 'rotate('+ this.degrees +'deg)'});
    },
    adjust(index){
        this.degrees = -(this.amt_rotate * index) + this.amt_rotate;
        $(".rotating").css({'transform' : 'rotate('+ this.degrees +'deg)'});
    }
  }
}
</script>

<style scoped>
.rotating {
  transform: rotateZ(60);
  transition: transform 1s ease-in-out;
}
</style>
