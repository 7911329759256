<template>
    <canvas :id="id"></canvas>
</template>

<script>
import star_img from './../assets/star.svg';
export default {
  props:["id", "positions", "static"],
  name:'Stars',
  data(){
    return{
      vue_canvas: null,
      star: null,
      STATE:{
        GROW: 0,
        DELAY: 1,
        SHRINK: 2
      }
    }
  },
  watch:{
    positions:function(){
      this.redraw();
    }
  },
  methods:{
    draw_star:function(){
      this.positions.forEach((item) => {
        if(!this.static)
        {
          if(item.state == this.STATE.GROW){
            if(item.size < (item.size_max))
              item.size = (item.size + item.speed);
            else
              item.state = this.STATE.SHRINK;
          }
          else if(item.state == this.STATE.DELAY){
            item.size = item.size_min;
            if(Math.random() < 0.01)
              item.state = this.STATE.GROW;
          }
          else if(item.state == this.STATE.SHRINK){
            if(item.size > (item.size_min))
              item.size = (item.size - item.speed);
            else
              item.state = this.STATE.DELAY;
          }
        }

        this.vue_canvas.drawImage(this.star,Math.ceil( item.x - item.size/2), Math.ceil(item.y - item.size/2), item.size, item.size);
      });
    },
    animate:function(){
      this.vue_canvas.clearRect(0, 0, this.vue_canvas.canvas.width, this.vue_canvas.canvas.height);
      this.draw_star();
    },
    redraw:function(){
      const dpr = window.devicePixelRatio;
      this.vue_canvas.canvas.width  = (window.innerWidth) * dpr;
      this.vue_canvas.canvas.height = (window.innerHeight) * dpr;

      this.vue_canvas.scale(dpr, dpr);

      this.vue_canvas.canvas.style.width = `${window.innerWidth}px`;
      this.vue_canvas.canvas.style.height = `${window.innerHeight}px`;

      this.animate();
    },
    load:function(){
      var c = document.getElementById(this.id);
      var ctx = c.getContext("2d");
      const dpr = window.devicePixelRatio;

      ctx.imageSmoothingEnabled = false;
      c.width  = (window.innerWidth) * dpr;
      c.height = (window.innerHeight) * dpr;

      ctx.scale(dpr, dpr);

      c.style.width = `${window.innerWidth}px`;
      c.style.height = `${window.innerHeight}px`;

      return ctx;
    },
    // requestAnimFrame:function(){
    //   return  window.requestAnimationFrame       ||
    //           window.webkitRequestAnimationFrame ||
    //           window.mozRequestAnimationFrame    ||
    //           function( callback ){
    //             window.setTimeout(callback, 1000 / 60);
    //           };
    // },
    animloop:function(){
      // this.requestAnimFrame(this.animloop);
      this.animate();
    }
  },
  mounted:function(){
    this.vue_canvas = this.load();
    this.star.addEventListener('load', () => {
      this.animate();
    });

    if(!this.static){
      setInterval(this.animate, 20);
    }
  },
  created:function(){
    this.star = new Image();
    this.star.src = star_img;
  }
}
</script>
