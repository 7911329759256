<template>
  <div></div>
</template>

<script>
export default {
  data(){
    return{
      keyboard: null,
      CONST:{
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        DOWN: 40
      }
    }
  },
  methods:{
    keypress:function(event){
      if(event.keyCode == this.CONST.LEFT) {
        this.keyboard = "left";
      }
      else if(event.keyCode == this.CONST.UP) {
        this.keyboard = "up";
      }
      else if(event.keyCode == this.CONST.RIGHT) {
        this.keyboard = "right";
      }
      else if(event.keyCode == this.CONST.DOWN) {
        this.keyboard = "down";
      }
    },
    keyRun(val, key, func, page){
      if(val == key)
        func(page);
      this.keyboard = null;
    }
  },
  mounted:function(){
    document.addEventListener('keydown', this.keypress);
  },
  beforeDestroy:function(){
    document.removeEventListener("keydown", this.keypress);
  }
}
</script>
