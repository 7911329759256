<template>
  <div id="main" :class='scrollabe'>
    <div class="landscape-div" v-show='portrait'>
      <div class="outer-border">
        <div class="base-dark">
          <Stars></Stars>
            <div class="shooting-star">
              <Lottie path='anim/shooting-star.json' :loop="true"/>
            </div>
            <transition name="cloud-back">
              <div class="cloud-container" v-if='VIEW != PAGE.ABOUT'>
                  <div class="cloud-background"></div>
                  <div class="cloud-midground"></div>
              </div>
            </transition>
          <Home @setView='setView' v-model='VIEW'></Home>
        </div>
        <transition name="scale">
          <button class="compass" v-if='VIEW == PAGE.HOME' @click='setView(PAGE.ABOUT)'>
              <Lottie path='anim/about-circle.json' :loop="true"/>
          </button>
        </transition>
      </div>
      <div :class="'cloud-page slide-y-animation ' + VIEW">
        <div class="cloud-background">
          <div class="cloud-foreground"></div>
        </div>
        <div class="cloud-art">
          <transition name="slide-art">
            <Art ref="art" v-model='VIEW' v-if='VIEW == PAGE.ART'></Art>
          </transition>
          <transition name="slide-art">
            <Navigation v-model='VIEW' v-if='VIEW == PAGE.ART'></Navigation>
          </transition>
        </div>

        <strong class="copyright"  v-if='VIEW != PAGE.ART'>© Aileen Zhou. {{year}}</strong>
      </div>
      <About v-model='VIEW'></About>
    </div>
    <Landscape :show='!portrait'></Landscape>
    <Loading v-model='loading' v-show='loading'></Loading>
  </div>
</template>

<script>
import Page from './mixins/Page.vue';
import Navigation from './components/Art-Back.vue';
import Landscape from './views/Landscape.vue';
import Stars from './components/StarUI.vue';

import Home from './views/Home.vue';
import Art from './views/Art.vue';
import About from './views/About.vue';

import Loading from './components/Loading.vue';
export default{
  mixins:[Page],
  data(){
    return{
      portrait: true,
      loading: false,
      show: false,
      VIEW: "home",
      star_canvas_width: window.innerWidth,
      star_canvas_height: window.innerHeight,
      animate_speed: 1,
      test:null
    }
  },
  components: {
    Stars,
    Home,
    About,
    Art,
    Navigation,
    Loading,
    Landscape
  },
  watch:{
    touch:function(val){
      if(this.VIEW == this.PAGE.HOME)
      {
        this.touchRun(val, "left", this.setView, this.PAGE.ART);
        this.touchRun(val, "right", this.setView, this.PAGE.CODE);
        this.touchRun(val, "down", this.setView, this.PAGE.ABOUT);
      }
      else if(this.VIEW == this.PAGE.ABOUT)
      {
        this.touchRun(val, "down", this.setView, this.PAGE.HOME);
      }
      else if(this.VIEW == this.PAGE.ART){
        if(this.$refs.art)
          this.$refs.art.controls(val);

        this.touch = null;
      }
      else if(this.VIEW == this.PAGE.CODE){
        this.touch = null;
      }
    },
    keyboard:function(val){
      if(this.VIEW == this.PAGE.HOME)
      {
        this.keyRun(val, "left", this.setView, this.PAGE.ART);
        this.keyRun(val, "right", this.setView, this.PAGE.CODE);
        this.keyRun(val, "down", this.setView, this.PAGE.ABOUT);
      }
      else if(this.VIEW == this.PAGE.ABOUT)
      {
        this.keyRun(val, "down", this.setView, this.PAGE.HOME);
      }
      else if(this.VIEW == this.PAGE.ART){
        this.$refs.art.controls(val);
        this.keyboard = null;
      }
      else if(this.VIEW == this.PAGE.CODE){
        this.keyboard = null;
      }
    }
  },
  methods:{
    home:function(){
      this.$router.push({ path: '/' })
    },
    setView:function(view){
      this.VIEW = view;
    },
    isPortrait:function(){
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        var min = (screen.availWidth < screen.availHeight) ? screen.availWidth : screen.availHeight;
        var max = (screen.availWidth > screen.availHeight) ? screen.availWidth : screen.availHeight;
        var ratio = (min / max);

        this.portrait = false;
        if(ratio > 0.575){
          //landscape mode: width should be greater than height
          if(screen.availWidth > screen.availHeight)
            this.portrait = true;
        }
        else{
          //portrait mode: height should be greater than width
          if(screen.availWidth < screen.availHeight)
            this.portrait = true;
        }
      }
    }
  },
  computed:{
    scrollabe:function(){
      return (this.VIEW != this.PAGE.ART || this.show) ? "no-scroll" : "";
    },
    year:function(){
      return new Date().getFullYear();
    }
  },
  mounted:function(){
    window.addEventListener('resize', this.isPortrait);
  },
  unmounted() {
    window.removeEventListener('resize', this.isPortrait);
  },
  created:function(){
    this.isPortrait();

    if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
    {
      this.portrait = false;
    }
  }
}
</script>
