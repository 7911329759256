<template>
  <div></div>
</template>

<script>
export default {
  data(){
    return{
      xDown: null,
      yDown: null,
      touch: null,
      MIN_DISTANCE: 80
    }
  },
  methods:{
    touchRun(val, key, func, page){
      if(val == key)
        func(page);
      this.touch = null;
    },
    getTouches:function(evt) {
      return evt.touches ||             // browser API
             evt.originalEvent.touches; // jQuery
    },
    handleTouchStart:function(evt) {
        const firstTouch = this.getTouches(evt)[0];
        this.xDown = firstTouch.clientX;
        this.yDown = firstTouch.clientY;
    },
    handleTouchMove:function(evt) {
        if ( ! this.xDown || ! this.yDown ) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = this.xDown - xUp;
        var yDiff = this.yDown - yUp;

        if(Math.abs( yDiff ) < this.MIN_DISTANCE && Math.abs( xDiff ) < this.MIN_DISTANCE)
          return;

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > 0 ) {
                /* right swipe */
                this.touch = "right";
            } else {
                /* left swipe */
                this.touch = "left";
            }
        } else {
            if ( yDiff > 0 ) {
                /* down swipe */
                this.touch = "down";
            } else {
                /* up swipe */
                this.touch = "up";
            }
        }
        /* reset values */
        this.xDown = null;
        this.yDown = null;
    }
  },
  mounted:function(){
    document.addEventListener('touchstart', this.handleTouchStart, false);
    document.addEventListener('touchmove', this.handleTouchMove, false);
  },
  beforeDestroy :function(){
    document.removeEventListener('touchstart', this.handleTouchStart, false);
    document.removeEventListener('touchmove', this.handleTouchMove, false);
  }
}
</script>
