import Vue from 'vue'
import VueRouter from 'vue-router'

import Portfolio from '../Portfolio.vue'
import AimeeCafe from '../projects/aimee-cafe/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Portfolio',
    component: Portfolio
  },
  {
    path: '/aimees-cafe',
    name: 'Aimees Cafe',
    component: AimeeCafe
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
