<template>
  <button class="button" @mouseover="hover = true" @mouseleave="hover = false" @click='show()'>
    <img :src='image_src' alt="">
    <div class="info" v-show='hover'>
      <div>
        <span>{{info.title}}</span>
        <p>{{info.desc}}</p>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  props:["info"],
  data(){
    return{
      folder:'./../../assets/icons',
      hover:false
    }
  },
  computed:{
    image_src:function(){
      var images = require.context('./../../assets/icons', false)
      return images("./" + this.info.img)
    }
  },
  methods:{
    show:function(){
      this.$emit("click");
    }
  },
  mounted:function(){

  }
}
</script>
