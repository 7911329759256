import Vue from 'vue'
import App from './App.vue'
import Lottie from 'lottie-vuejs/src/LottieAnimation.vue';
import router from './router'

Vue.component('Lottie', Lottie);

import './sass/app.scss';

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
