<template>
  <div id="about">
    <div class="about-wrapper">
      <!-- <button type="button" name="button" @click='test = !test'>press</button> -->
      <transition name="slide-about-header">
        <div class="about-header" v-if='value == PAGE.ABOUT'>
          <Lottie path='anim/who.json' :loop="false"/>
        </div>
      </transition>
      <div class="desc">
        <transition name="slide-about-text">
          <div v-if='value == PAGE.ABOUT'>
            <span><span class="comma">"</span> {{data[index].quote}} <span class="comma">"</span></span>
            <!-- <span>- {{data[index].anon}}</span> -->
            - {{data[index].anon}}
            <span class="indicate">
              <button type="button" name="button" @click='next()'></button>
            </span>
          </div>
        </transition>
      </div>
        <div class="about-img">
          <!-- <button class="btn-close" type="button" name="button"></button> -->
          <transition name="slide-about">
            <button v-if='value == PAGE.ABOUT' class="btn-home" type="button" name="button" @click='home()' tabindex='-1'>
              <img :src="image_src" alt="">
            </button>
          </transition>
          <transition name="slide-about-media">
            <div class="media column" v-if='value == PAGE.ABOUT'>
              <button type="button" name="button" @click='openEmail()'><span>E-MAIL</span></button>
              <button type="button" name="button" @click='goToTwitter()'><span>TWITTER</span></button>
              <button type="button" name="button" @click='goToInstagram()'><span>INSTAGRAM</span></button>
            </div>
          </transition>
        </div>
    </div>
  </div>
</template>

<script>
import Page from './../mixins/Page.vue';
import AboutJSON from './../json/about.json';
export default {
  mixins:[Page],
  props:["value"],
  data(){
    return{
      data: AboutJSON,
      index: 0
    }
  },
  computed:{
    image_src:function(){
      var images = require.context('./../assets/', false)
      return images("./half-face.svg")
    },
    time:function(){
      var d = new Date();
      return d.toLocaleString('en-US', { timeZone: 'America/New_York' });
    }
  },
  methods:{
    next(){
      this.index = (this.index + 1) % this.data.length;
    },
    home:function(){
      this.$emit("input", "home");
    },
    openEmail(){
      window.location.href = "mailto:ayleinee@gmail.com";
    },
    goToTwitter(){
      window.open("https://twitter.com/ayleinee/");
    },
    goToInstagram(){
      window.open("https://instagram.com/ayleinee/");
    }
  },
  mounted:function(){
  },
  components:{
  }
}
</script>
