<template>
  <div class="code-modal">
      <div class="parallax"></div>
      <div class="header">
        <h1>
          <img :src='icon_image(project.img)' alt="">
          {{project.title}}
        </h1>
        <span class="row">
          <span v-for='(tool, index) in project.tools' v-bind:key='index'>{{tool}}</span>
        </span>
      </div>
      <div class="overview"></div>
      <div class="section" v-for='page in pages' v-bind:key='page.index'>
        <h2>{{page.title}}</h2>
        <p v-html='page.desc'></p>
        <div class="img-set">
          <div class="img-slot" v-for='(img, index) in page.img' v-bind:key="index" >
            <img  :src='image(img)' alt="">
          </div>
        </div>
      </div>
    <button class="btn-close" type="button" name="button" @click='$emit("click")'></button>
  </div>
</template>

<script>
import Page from './../../mixins/Page.vue';
import Stars from './../Stars.vue';
export default {
  mixins:[Page],
  props:["value", "project", "pages"],
  data(){
    return{
      MAX: 6
    }
  },
  watch:{
    keyboard:function(val){
      this.keyRun(val, "right", this.next, null);
      this.keyRun(val, "left", this.prev, null);
    },
    touch:function(val){
      this.touchRun(val, "right", this.next, null);
      this.touchRun(val, "left", this.prev, null);
    }
  },
  methods:{
    next:function(){
      let nextValue = (this.value + 1) % this.MAX;
      this.$emit("pageChange", nextValue);
    },
    prev:function(){
      let prevValue = (this.value - 1 >= 0) ? (this.value - 1) : (this.MAX - 1);
      this.$emit("pageChange", prevValue);
    },
    icon_image:function(img_name){
      var images = require.context('./../../assets/buttons/code', false)
      return images("./" + img_name)
    },
    image:function(file){
      if(file){
        var images = require.context('./../../assets/code', false)
        return images("./" + file)
      }
      return '';
    }
  },
  computed:{},
  mounted:function(){},
  created:function(){},
  components:{},
  Stars
}
</script>
