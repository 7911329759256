<template>
  <div v-if="show" class="landscape-div outer-border">
    <div class="base-dark responsive">
        <span>
          {{text}}
          <div class="star">
            <Lottie path='anim/shooting-star.json' :loop="true"/>
          </div>
        </span>
      </div>
  </div>
</template>

<script>
export default {
  props:["show"],
  data(){
    return{
      text: "Please rotate your device."
    }
  },
  created:function(){
    if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
    {
      this.text = "Sorry, this browser is not supported. Please use a different one.";
    }
  }
}
</script>
