<template>
  <div class="full-canvas">
    <Stars id='static-canvas' :positions='static_positions' :static='true'></Stars>
    <Stars id='canvas' :positions='animation_positions'></Stars>
  </div>
</template>

<script>
import Stars from './Stars.vue';
export default {
  data(){
    return{
      static_positions:[],
      animation_positions:[],
      blink_speed: 2,
      CONST:{
        SMALL: 'small',
        MED: 'med',
        LARGE: 'large'
      },
    }
  },
  methods:{
    generate_position:function(){
      const grid_width = 40;
      const grid_height = 40;
      const star_size_max = 40;
      const star_suze_med = 20;
      const star_size_min = 5;
      const star_min = 5;

      let grid_x_count = Math.ceil(window.innerWidth / grid_width);
      let grid_y_count = Math.floor(window.innerHeight / grid_height);

      for(let i = 0; i < grid_x_count + 1; i++){
        for(let j = 0; j < grid_y_count + 1; j++){
          let perc = Math.random();
          let star_size = star_size_min;
          let type = this.CONST.SMALL;

          if(perc < 0.05){
            star_size = star_size_max;
            type = this.CONST.LARGE;
          }
          else if(perc < 0.4){
            star_size = star_suze_med;
            type = this.CONST.MED;
          }

          let size = Math.ceil((Math.random() * star_size) + star_min)
          var star = {
            x: Math.floor((Math.random() * ((i+1) * grid_width)) + (i * grid_width) - grid_width/2),
            y: Math.floor((Math.random() * ((j+1) * grid_height)) + (j * grid_height)),
            size: size,
            size_min: 0,
            size_max: Math.floor(size + ((Math.random() * 5) + 1)),
            state: 0,
            speed: Math.ceil((Math.random() * this.blink_speed))
          };

          if(type == this.CONST.SMALL)
            this.static_positions.push(star);
          else
            this.animation_positions.push(star);
        }
      }
    },
    redraw:function(){
      this.static_positions = [];
      this.animation_positions = [];
      this.generate_position(this.vue_canvas);
    },
  },
  mounted:function(){},
  created:function(){
    this.generate_position();
    window.addEventListener("resize", this.redraw);
  },
  beforeDestroy:function(){
    window.removeEventListener("resize", this.redraw);
  },
  components:{
    Stars
  }
}
</script>
