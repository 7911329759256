<template>
  <div id="back">
    <!-- <span class="half-circle btn-menu-under"> -->
      <button type="button" name="button" @click="return_home">
        <span></span>
      </button>
    <!-- </span> -->
  </div>
</template>

<script>

export default {
  data(){
    return{
      show: false
    }
  },
  methods:{
    return_home:function(){
      window.scrollTo(0,0);
      document.fullscreenEnabled = false;
      this.$emit("input", "home");
    }
  },
  computed:{
    image_src:function(){
      var images = require.context('./../assets/', false)
      return images("./bunny-small.svg")
    },
  }
}
</script>
