<template>
  <div id="aimee-css">
    <div class="top">
      <img class="logo" :src='logo()' alt="">
    </div>
    <div class="center">
      <div class="left">
        <span class="align-right"></span>
      </div>
      <div class="video">
        <iframe class="frame" src="https://www.youtube.com/embed/dju7_kdydY8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div class="right">
        <span></span>
      </div>
    </div>
    <div class="bottom">
      <div class="mushroom-left"></div>
      <div class="board"></div>
      <div class="mushroom-right"></div>
    </div>
    <div class="grain">

    </div>
  </div>
</template>

<script>
export default {
  methods:{
    logo:function(){
      var name = "sign.svg";
      var images = require.context('./imgs', false)
      return images("./" + name)
    },
  }
}
</script>
